import "src/components/StyledImage/StyledImage.css.ts.vanilla.css!=!../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"src/components/StyledImage/StyledImage.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA62V3W6jMBCF7/MU3ESiUrFmbI9/6It0LwmhCVsCKXF2V13tu6+ALAES3E1aKRdIsf3NOXM8ZusifX9dQfB7EQT76pC7vCrjoM6KxOU/sqfFnwXrlmC75D3Ky3X2Kw4Qnj7awdsdaZHvo33itnGQ5nVaZOHDYI2cgJPVoSqOLmsO32b5ZuviIDm6arBFjQvhbSE/83VDEMAIEIwVXCo0Wqhl86+r9nEgqf2uu0PbXa5OysNLVe/i7rNIXPYcSloOS9QeHldMWy0MKQ4WCYRoGUX20iMaNCznaN/CiGCEM2McDWgSmRXGWm04CgKtp7BV5Vy18/HCCM3yMdCMj6CJTyMyZZSWyhptCQ3os6cEF3qvWhrpsacrXw+RCU4CkIM0aLm5aNs/mUSzOp/Dia2ph2gFGwJPvl4joo8ox8T1nTmF/0vpRF/moSli2gg8uzqXG+6TF/ExcfOJoH5wK8JIwJWUbn2OKqYu73fv51UKp+VjML1/+b3R7ET2Vs6ru+jd9y/J5nzvwkg2dsKQ+doyd0m9ycuoj3dTdL+k9JSFmkkA2XigOaKWVzuMPhOmxlcenOTsxDlBL2d5P/tmXRBNuyfQvW/wCUbcSkStpAYyZnJHO7V0i8a322fCSNfNY6H+zFg4j/j+POfzyzKOBkFZS8ZIfQpFlwTv43tIk6J5CvFhKIWP34zj/LwRxNDQ4HfPw9ikAzv3/gKhQBjEFgkAAA==\"}!../../../node_modules/@vanilla-extract/webpack-plugin/extracted/dist/vanilla-extract-webpack-plugin-extracted.cjs.js"
export var image = 'dlczkb3';
export var imageWrap = 'dlczkb1';
export var root = 'dlczkb0';
export var shapesLayout1 = {imageWrap:'dlczkb2',shapeSemicircles:'dlczkb6 dlczkb4',shapeSemicircleOutline:'dlczkb7 dlczkb4',shapeCirclesOutline:'dlczkb8 dlczkb4'};
export var shapesLayout2 = {imageWrap:'dlczkb2',shapeSemicircle:'dlczkba dlczkb4',shapeDiamond:'dlczkbb dlczkb4',shapeSquiggle:'dlczkbc dlczkb4'};
export var shapesLayout3 = {imageWrap:'',shapeSemicircles:'dlczkbd dlczkb4',shapeSquiggle:'dlczkbe dlczkb4'};
export var shapesLayoutFeature = {imageWrap:'dlczkbm',shapeSemicircles:'dlczkbn dlczkb4',shapeHelix:'dlczkbo dlczkb4'};
export var shapesLayoutGraph = {imageWrap:'dlczkb2',shapeSemicircle:'dlczkbt dlczkb4',shapeCirclesOutline:'dlczkbu dlczkb4'};
export var shapesLayoutHome = {imageWrap:'dlczkb2',shapeCirclesOutline:'dlczkbg dlczkb4',shapeSun:'dlczkbh dlczkb4',shapeDiamond:'dlczkbi dlczkb4',shapeSquiggle:'dlczkbj dlczkb4',shapeSquiggle1:'dlczkbk',shapeSquiggle2:'dlczkbl'};
export var shapesLayoutSearch = {imageWrap:'',shapeCircle:'dlczkbp dlczkb4',shapeSemicircles:'dlczkbq dlczkb4',shapeSquiggle:'dlczkbr dlczkb4'};