import { useNextHydrated } from '@lib/utils/createToggleStore';
import { GoogleAnalytics } from '@next/third-parties/google';
import { useRouter } from 'next/router';

const ANALYTICS_KEY = process.env.NEXT_PUBLIC_ANALYTICS_KEY;

export const SeoScripts = () => {
  const { asPath } = useRouter();
  const nextHydrated = useNextHydrated();

  if (!nextHydrated || !ANALYTICS_KEY) return <></>;

  return (
    <GoogleAnalytics gaId={ANALYTICS_KEY} />
  );
};
